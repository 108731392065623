import React from "react"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"
import { css } from "@emotion/core"
import LightBox from "components/UI/LightBox"
import { CardWrapper } from "../components/UI/Card"
import InternalLink from "../components/UI/InternalLink"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import ColumnTitle from "components/UI/ColumnTitle"
import ContentDecoration from "components/UI/ContentDecoration"
import List from "components/UI/List"
import linkResolver from "utils/linkResolver"
import Button from "components/UI/Button"
import sort from "utils/sort"
import Editor from "components/Editor"
import t from "theme"

//uses GraphQL back reference - which automatically links articles back to company
export const query = graphql`
    query($ticker: String!) {
        selectedCompany: contentfulCompany(ticker: { eq: $ticker }) {
            ...CompanyFields
            article {
                ...ArticleContent
            }
        }
    }
`

const ArticleArchive = ({ pageContext: { commonData, ticker }, data: { selectedCompany }, location }) => {
    const featuredArticles = getFeaturedArticles(commonData, ticker)
    const articles = selectedCompany.article

    return (
        <MainLayout
            header={
                <Header
                    commonData={commonData}
                    selectedCompany={selectedCompany}
                    selectedFeature="Articles"
                    pathname={location.pathname}
                />
            }
            sidebar={<FeaturedArticlesSidebar title={"FEATURED ARTICLES"} featureds={featuredArticles} />}>
            <PageMetadata
                pageTitle={selectedCompany.shortName + " Articles"}
                pageDescription={"The best research articles on " + selectedCompany.shortName}
                pagePathname={location.pathname}
            />

            <div>
                <ColumnTitle>{"LATEST ARTICLES ON " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>
                <LightBox
                    toggleComponent={
                        <Button
                            css={css`
                                width: 100%;
                                font-size: ${t.font.size[6]};
                                margin-bottom: ${t.space[7]};
                            `}>
                            WRITE AN ARTICLE
                        </Button>
                    }>
                    <ColumnTitle>WRITE AN ARTICLE</ColumnTitle>

                    <div
                        css={css`
                            height: 50vh;
                        `}>
                        <Editor />
                    </div>
                </LightBox>
                <List
                    items={articles.sort(sort("sortTimestamp", "desc")).map((article, index) => (
                        <CardWrapper shadow>
                            <InternalLink
                                decoration={
                                    <ContentDecoration type={article.articleType} date={article.sortTimestamp} />
                                }
                                headline={article.headline}
                                pathname={linkResolver({ slug: article.slug, type: "article" })}
                                preview={article.shortDescription.shortDescription}
                            />
                        </CardWrapper>
                    ))}
                    space={t.space[5]}
                    nodivider
                />
            </div>
        </MainLayout>
    )
}

export default ArticleArchive
